import Swiper, {Navigation, Autoplay} from "swiper";
import 'swiper/css'
import 'swiper/css/autoplay'
import {gsap} from 'gsap'

document.addEventListener("DOMContentLoaded", () => {
    const swiper = new Swiper('.front-page-swiper', {
        modules: [Navigation, Autoplay],
        direction: 'horizontal',
        spaceBetween: 150,
        allowTouchMove: true,
        autoplay: {
            delay: 10000,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })

    swiper.on('slideNextTransitionStart', ({$el}) => {
        moveOutText($el[0], -1)
    })

    swiper.on('slidePrevTransitionStart', ({$el}) => {
        moveOutText($el[0], 1)
    })

    const moveOutText = (sliderDOM, m) => {
        const slideActive = sliderDOM.querySelector('.swiper-slide-active')
        const slideContent = slideActive.querySelectorAll('.content > *')

        slideContent.forEach((content, i) => {

            gsap.set(content, {
                x: 140 * m,
                opacity: 0,
            })

            gsap.to(content, {
                delay: i * .1,
                x: 0,
                opacity: 1,
            })
        })
    }
});
